.question_and_answers_page {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  margin-top: 70px;
  margin-bottom: 70px;
  width: 80%;
}
h2 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
}
.question_answers_Container {
  width: 80%;
  margin: 0 auto;
  padding: 30px;
  margin-top: 70px;
}
.question {
  margin-bottom: 10px;
}
.question h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 3px;
}
.question h5 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 3px;
}
.question p {
  font-size: 14px;
  color: #0a0a0aac;
  line-height: 1.2;
}
.answers > p {
  font-size: 22px;
  font-weight: bold;
}
.answer_container {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}
.answers h3 {
  font-size: 22px;
  margin-bottom: 10px;
}
.answer_item {
  position: relative;
  top: -17px;
  display: flex;
  align-items: center;
  border: none;
  color: #302e2ef0;
  padding-top: 10px;
}
.answer_body {
  font-size: 16px;
  line-height: 1.6;
  padding-bottom: 12px;
}
.answer_username {
  font-size: 14px;
  color: #555;
}
.answer_form {
  margin-top: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
}
.answer_form > h2,
.answer_form p {
  text-align: center;
}
.answer_form > h2 {
  font-size: 22px;
  font-weight: bold;
}

.answer_form textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.answer_form button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
}

.post {
  color: green;
}
button:hover {
  background-color: #095aab;
}

.errorMess {
  border-color: black;
  background-color: #ffe6e6;
}
