* {
  font-family: cursive;
}
.questionsPage {
  box-sizing: border-box;
}

.welcomeMessage {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.welcomeMessage h1 {
  font-size: 1.3rem;
  font-weight: 900;
}
.welcomeMessage span {
  color: #fe8082;
  font-weight: 900;
  font-size: 1.3rem;
}
.welcomeMessage,
.questionsList {
  max-width: 1200px; /* Adjust as needed for your design */
  margin: 0 auto; /* Center the container horizontally */
  padding: 0 25px; /* Add padding to ensure content doesn’t touch edges */
}
/* .container {
  display: flex;
  justify-content: center; 
} */

.search {
  background-color: white;
  outline: none;
  width: 90%;
  /* max-width: 800px; */
  padding: 10px;
  border: 0.3px solid rgb(204, 204, 204);
  border-radius: 5px;
  color: rgb(2, 37, 83);
  font-family: monospace;
  margin-left: 40px;
}
.li {
  margin: 20px;
}
@media (max-width: 768px) {
  .welcomeMessage,
  .questionsList {
    padding: 0 20px; /* Adjust padding for smaller screens */
  }
}
.askQuestionButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 9px 25px;
  border-radius: 5px;
  margin-top: 120px;
  margin-bottom: 20px;
  margin-left: 15px;
  cursor: pointer;
  font-size: 18px;
}
.icon {
  font-size: 28px; /* Adjust the size as needed */
  transition: transform 0.3s ease; /* Transition effect for transform property */
  stroke-width: 40;
}
.askQuestionButton:hover {
  background-color: #0056b3;
}
.questionsList h2 {
  margin-bottom: 15px;
}

.questionItem {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.questionItem:hover {
  background-color: #f5f5f5;
}

.username {
  color: #555;
  font-size: 16px;
  text-align: left;
}
.username h5 {
  padding-bottom: 0 !important;
}
.questionerInfo {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  justify-content: space-between;
  position: relative; /* Ensure positioning context */
  padding-right: 20px;
}
.questionerInfo:hover .icon {
  transform: translateX(10px); /* Move the icon 10px to the right on hover */
}
.questionerInfo > div {
  padding: 5px;
}
.questionerInfo:hover .questionerImage {
  content: url("../../assets/user\ 2.png"); /* Change to the new image URL */
}
.questionerInfo h3 {
  flex-grow: 1; /* Allow h3 to take up remaining space */
  padding-left: 30px;
  font-size: 1.2rem;
}
