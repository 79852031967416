.loginContainer {
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 50px;
  padding-top: 10px;
  background-image: url("../../../assets/BackGroungIMG.svg");
}

.login_inner {
  text-align: center;
  background-color: white;
  border-radius: 5px;
  height: auto;
  min-height: 450px;
  padding: 15px 30px 25px;
  margin-top: 10px;
  width: 100%;
}

.Carousel_inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 10px;
}
.Carousel_inner h3 {
  font-size: 1.25rem;
  font-weight: bolder;
}
a:link {
  text-decoration: none;
}
span a {
  color: #fe8402;
}
form {
  margin-top: 30px;
  text-align: left;
}
.form input {
  position: relative; /* Ensure correct positioning for the line effect */
  border: none;
  padding: 10px 40px 10px 15px;
  background-color: inherit;
  width: 100%;
  border-radius: 5px;
  border: 0.5px solid rgb(202, 202, 202);
  outline: none;
  position: relative; /* Ensure the input position is relative for correct span positioning */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

form input:focus {
  width: 100%; /* Full width of the container */
  box-sizing: border-box;
  border: 0.5px solid rgb(202, 202, 202);
}
.error_input input {
  padding: 10px 40px 10px 15px;
  width: 100%;
  border-radius: 5px;
  background-color: #fee6e6;
  border: 0.5px solid rgb(202, 202, 202);
  outline: none;
}
.line {
  height: 3px;
  width: 0px;
  border-radius: 5px;
  background-color: rgb(218, 96, 96);
  display: block;
  transition: 0.9s width ease-in-out;
  position: relative;
  top: -2px;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}
.form input:focus + .line {
  width: 100%;
  background-color: rgb(218, 96, 96);
  margin: 0px;
  padding: 0px;
}

.forget a {
  display: block;
  text-decoration: none;
  color: rgb(254, 132, 2);
  text-align: right;
  margin-top: 15px;
}
.btn button {
  font-size: 1rem;
  padding: 5px 10px;
  width: 100%;
  border-radius: 5px;
  border: none;
  margin-top: 15px;
  color: white;
  background-color: rgb(81, 108, 240);
}
.btn button:hover {
  background: #fe8402;
}
.password_toggle:hover {
  color: rgb(218, 96, 96); /* Set the icon color */
}

.password_toggle,
.password_toggle_error {
  position: absolute;
  right: 10px; /* Adjust to suit your design */
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1; /* Ensure it's above other elements */
}

.password_toggle_error:hover {
  color: rgb(218, 96, 96); /* Set the icon color */
}
.error_message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}
.from_input {
  position: relative;
}

.error_input {
  position: relative;
}
@media (min-width: 768) {
  .login_inner_contanier {
    padding: 10px 40px;
    width: 100%;
    margin-top: 5px;
    border-radius: 5px;
    border: 0.5px solid rgb(202, 202, 202);
  }
  .login_inner {
    width: 90%;
    margin: 25px auto;
  }
}
.server_error {
  color: #ff0000a9;
  margin-top: 10px;
  /* font-size: 15px; */
  font-weight: bold;
  /* display: inline; */
}


