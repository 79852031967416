.header_contanier {
  width: 100%;
  height: 75px;
  background-color: white;
  z-index: 999 !important;
  font-weight: 600;
  font-size: 1rem;
}
.logout{
  width: 120px;
  background-color: rgb(81, 108, 240) !important;
  color: white !important;
  border-radius: 10px;
  border: 1px solid none;
  /* font-size: 0.5rem; */
}
.sigIn{
  width: 120px;
  color: white !important;
  border-radius: 10px;
  border: 1px solid none;
  /* font-size: 0.5rem; */
}
